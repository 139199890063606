import { Container, Grid } from '@material-ui/core'
import React from 'react'
import Slider from 'react-slick';
import Layout from '../../components/layout'
import GridRow from '../../components/_grid-row'


import pageStyles from '../../assets/scss/class-internal.module.scss'
import ramyImg from '../../images/team/ramy.jpg';

export default function Ramy() {
    const sliderImages = [
        ramyImg
    ];

    const settings = {
        dots: true,
        infinite: false,
        arrows: false,
        speed: 500,
        slidesToShow: 3.6,
        slidesToScroll: 3.6,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2.05,
                    slidesToScroll: 2.05,
                    infinite: false,
                    dots: true
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1.02,
                    slidesToScroll: 1.02,
                    infinite: false,
                    dots: true
                }
            },
        ]
    };

    return (
        <React.Fragment>
            <Layout pageName="Meet the Team" backLink="/team">
                <div className={["class-internal", pageStyles.wrapper].join(" ")}>
                    <Container maxWidth="xl">
                        <GridRow>
                            {/* Slider section */}
                            <Grid container spacing={3} justify="center">
                                <Grid item xs={12}>
                                    {sliderImages?
                                    <Slider  {...settings}>
                                        {
                                            sliderImages.map(img => {
                                                return (
                                                    <div>
                                                        <img src={img}/>
                                                    </div>
                                                )
                                            })
                                        }

                                    </Slider>
                                    :
                                    ""

                                    }
                                </Grid>
                                {/* Content section */}
                                <Grid item xs={12} lg={8}>
                                    <div className={pageStyles.header}>
                                        <h2 className="h2">Ramy Metwally</h2>
                                    </div>
                                    <div className={pageStyles.desc}>
                                        <p>
                                            Ramy is our go-to resident boxing and boxercise coach. He is also a champion boxer, ranked nationally, and teaches adults and kids in our playground how to mindfully use the ancient principles of combat sports to be more confident, and aware in their day to day life. 
                                            <br /><br />
                                            For those wanting to break a sweat, and get stronger using the practice of boxing, Ramy will guarantee that you "crawl" out of the playground filling knocked-out! 
                                            <br /><br />
                                            To inquire or book, please send an email to info@beachmonkeyz.com.
                                        </p>
                                    </div>
                                    <Grid item xs={12} md={6} lg={5}>
                                        <div className={pageStyles.reserveButton}>
                                            <a href="mailto:info@beachmonkeyz.com" className={pageStyles.emailLink}>
                                                Send us an e-mail
                                            </a>
                                        </div>
                                    </Grid> 
                                </Grid>
                            </Grid>
                        </GridRow>
                    </Container>
                </div>
            </Layout>
        </React.Fragment>
    )
}
